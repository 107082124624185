<template>
  <div class="wrap">
    <div class="top" @click="handleToOpen">
      <div class="open">
        <div class="item">
          <div class="title">{{ singleStake.lockDay }}</div>
          <div class="value">{{ title }}</div>
        </div>
        <div class="item">
          <div class="title">{{ singleStake.lockQuantity }}</div>
          <div class="value">{{ totalAmount }}</div>
        </div>
      </div>
      <div class="arrow">
        <i class="iconfont icon-jiantouxia" v-if="!isOpen"></i>
        <i class="iconfont icon-jiantoushang" v-else></i>
      </div>
    </div>
    <div class="line" v-show="isOpen"></div>
    <template v-if="isOpen">
      <RegularListItem
        class="bottom"
        v-for="(item, index) in stakeList || []"
        v-show="item.status"
        :key="item.lockTimestampUtil"
        :dataSource="item"
        :lockSecond="lockSecond"
        :withdrawIndex="(pageNo - 1) * 5 + index"
        :pid="pid"
        :poolInfo="poolInfo"
        :punishRate="punishRate"
      ></RegularListItem>
      <div class="page-group" v-if="dataSource.length > 5">
        <span
          class="iconfont icon-jiantou_liebiaoxiangzuo_o"
          @click="handleFrontPage"
          :class="{ gray: this.pageNo === 1 }"
        ></span>
        <span
          class="iconfont icon-jiantou_liebiaoxiangyou_o"
          @click="handleNextPage"
          :class="{
            gray: this.pageSize * this.pageNo >= this.dataSource.length,
          }"
        ></span>
      </div>
      <div class="none-data" v-if="!dataSource || dataSource.length === 0">
        {{ singleStake.none }}
      </div>
    </template>
  </div>
</template>

<script>

import RegularListItem from './RegularListItem.vue'
export default {
  name: 'RegularList',
  props: ['title', 'dataSource', 'lockSecond', 'pid', 'poolInfo', 'punishRate'],
  data () {
    return {
      isOpen: false,
      totalAmount: 0,
      pageNo: 1,
      pageSize: 5,
      stakeList: []
    }
  },
  computed: {
    singleStake () {
      return this.$t('staking.singleStake')
    }
  },
  methods: {
    handleToOpen () {
      this.isOpen = !this.isOpen
    },
    handleFrontPage () {
      if (this.pageNo === 1) return
      this.pageNo--
      this.stakeList = this.dataSource.slice((this.pageNo - 1) * this.pageSize, this.pageNo * this.pageSize)
    },
    handleNextPage () {
      if (this.pageSize * this.pageNo >= this.dataSource.length) return
      this.pageNo++
      this.stakeList = this.dataSource.slice((this.pageNo - 1) * this.pageSize, this.pageNo * (this.pageSize))
    }
  },
  mounted () {
  },
  watch: {
    dataSource (val) {
      this.totalAmount = 0
      val.forEach(item => {
        if (item.status) {
          this.totalAmount += (item.amount / 1000000000000000000)
        }
      })
      this.stakeList = val.slice(0, 5)
    }
  },
  components: { RegularListItem }
}
</script>

<style scoped lang="scss">
.wrap {
  padding: 15px 0;
  margin-bottom: 15px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 15px;
  .line {
    height: 1px;
    background-color: #e3e3e3;
    margin: 10px 0;
  }
  .left {
    width: 90%;
  }
  .top {
    display: flex;
    padding: 0 15px;

    justify-content: space-between;

    .open {
      display: flex;
      .item {
        font-weight: bold;
        width: 100px;
        .title {
          color: #818086;
          font-size: 12px;
        }
        .value {
          font-size: 15px;
          color: #16172a;
        }
      }
    }
  }
  .bottom {
    font-size: 12px;
    border-bottom: 1px solid #e3e3e3;
  }
  .bottom:last-child {
    border: none;
  }
  .none-data {
    font-size: 12px;
    color: #818086;
    text-align: center;
  }
}

.page-group {
  display: flex;
  width: 250px;
  margin: 0 auto;
  justify-content: space-around;
  margin-top: 10px;
  .iconfont {
    font-size: 30px;
  }
}
.gray {
  color: #adadaf;
}
</style>

import { render, staticRenderFns } from "./StakePop.vue?vue&type=template&id=0f16e980&scoped=true&"
import script from "./StakePop.vue?vue&type=script&lang=js&"
export * from "./StakePop.vue?vue&type=script&lang=js&"
import style0 from "./StakePop.vue?vue&type=style&index=0&id=0f16e980&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f16e980",
  null
  
)

export default component.exports
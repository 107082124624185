<template>
  <VanPopup
    v-model="addShow"
    style="width: 93%; max-width: 400px"
    round
    @click-overlay="closePop"
  >
    <div class="lp-wrap">
      <div class="title-wrap">
        <div class="title">
          <div class="title">{{ singleStake.stake }}MEB</div>
          <div class="close-icon" @click="closePop">✕</div>
        </div>
      </div>
      <div class="select-stake">
        <div class="title">{{ singleStake.choseStake }}</div>
        <div class="select-group">
          <div
            class="select-item"
            v-for="(item, index) in poolList"
            :key="item.lockSecond"
            :class="{ 'active-item': index === currentSelect }"
            @click="handleChangeStake(index)"
          >
            <span v-if="index !== 0"
              >{{ item.stakeTime }}{{ getUnit(item.lockSecond) }}</span
            >
            <span v-else>{{ singleStake.current }}</span>
          </div>
        </div>
        <div class="key-value">
          <div class="key" v-show="currentSelect === 0">
            {{ singleStake.currentWeight }}
          </div>
          <div class="key" v-show="currentSelect !== 0">
            {{ singleStake.stakeWeight }}
          </div>
          <div class="value">X {{ xMebRate }}</div>
        </div>
        <div class="key-value">
          <div class="key" v-show="currentSelect === 0">
            {{ singleStake.currentApy }}
          </div>
          <div class="key" v-show="currentSelect !== 0">
            {{ singleStake.stakeApy }}
          </div>
          <div class="value">{{ apy }}</div>
        </div>
        <div class="key-value">
          <div class="key">{{ singleStake.getMDAO }}</div>
          <div class="value">{{ xMebAmount || 0 }}</div>
        </div>
      </div>
      <div class="lp-box">
        <div class="lp-balance">
          <div>{{ stake.enterAmount }}</div>
          <div>{{ stake.balance }}:{{ mebBalance }} MEB</div>
        </div>
        <div class="input-box">
          <input
            class="input-border"
            v-model="mebAmount"
            oninput="value=value.match(/^\d+(?:\.\d{0,8})?/)"
          />
          <div class="max-btn" @click="handleToMax">{{ staking.max }}</div>
        </div>
      </div>
      <div class="lp-btn-groups">
        <van-button
          class="approve-btn"
          ref="authMEB"
          color="#66eab9"
          @click="approveMEBContract"
          v-if="!authMEB"
          :loading="authMEBLoading || btnLoading"
        >
          {{ staking.authMEB }}
        </van-button>
        <van-button
          class="approve-btn"
          :disabled="mebAmount <= 0 || mebAmount > mebBalance"
          v-else
          color="#66eab9"
          @click="handleStake"
          :loading="btnLoading"
        >
          {{ singleStake.comfirm }}
        </van-button>
      </div>
    </div>
  </VanPopup>
</template>
<script>
import { mapState } from 'vuex'
import config from '@/config/app.config'
import singleLock from '@/utils/singleStake'
const contractsInfo = config.contractsInfo
export default {
  name: 'stakePop',
  data () {
    return {
      currentSelect: 0, // 当前选择
      poolBalance: 0, // 池子余额
      awardPerSecond: 0,
      addShow: true,
      // tokenMeb: contractsInfo.tokenGEPContract, // meb
      tokenMeb: contractsInfo.tokenMMebContract, // mMeb
      mebAmount: 0, // 需要的meb数量
      authMEBLoading: false, // 授权Loading
      getXMebLoading: false, // 获取loading
      btnLoading: true, // 质押Loading
      authMEB: false, // 是否授权meb
      mebBalance: 0, // meb余额
      totalAllocPoint: 0,
      mebAllowance: 0, // meb授权额度
      apy: 0,
      weight: 0,
      xMebRate: 0,
      xMebAmount: 0,
      interval: 0

    }
  },
  props: ['poolList', 'lastTime'],
  computed: {
    ...mapState(['myAcount']),
    stake () {
      return this.$t('staking')
    },
    stakeTips () {
      return this.$t('common.stakeTips')
    },
    staking () {
      return this.$t('staking.addLiquidty')
    },
    singleStake () {
      return this.$t('staking.singleStake')
    }
  },
  methods: {
    init () {
      this.getMebBalance()
      // 获取授权额度
      this.getAllowance()
      this.getTotalAllocPoint()
      this.getUserDepositInterval()
    },
    closePop () {
      this.$emit('close')
    },
    handleChangeStake (index) {
      this.currentSelect = index
      this.xMebRate = this.poolList[index].xMebExchangeRate / 1e18
      this.getApy(+this.poolList[index].allocPoint)
      this.xMebAmount = this.$gbUtils.formatThreeBalance((this.mebAmount * this.xMebRate))
    },
    // 授权MEB
    async approveMEBContract () {
      this.authMEBLoading = true
      const MEBObj = {
        abi: this.tokenMeb.abi,
        address: this.tokenMeb.address
      }
      const resp = await singleLock.approveContract(MEBObj, 100000000000000000) // 授权MEB
      this.authMEBLoading = false
      if (!resp.success) return this.$toast(resp.message.message)
      // console.log('签名回调', resp)
      this.authMEB = true
      this.getAllowance()
    },
    // 获取余额
    async getMebBalance () {
      const mebResp = await this.$web3.balanceOf(this.tokenMeb)
      if (mebResp.success) {
        this.mebBalance = this.$gbUtils.formatThreeBalance(mebResp.result) || 0
      }
    },
    // 获取授权额度
    async getAllowance () {
      // 获取授权额度
      this.btnLoading = true
      const resp = await singleLock.allowance(this.tokenMeb)
      if (resp.success) {
        this.mebAllowance = resp.result
        console.log('this.mebAllowance', this.mebAllowance)
        this.authMEB = this.mebAllowance > 0
      }
      // console.log(this.authMEB)
      this.btnLoading = false
    },
    // meb最大
    handleToMax () {
      this.mebAmount = this.mebBalance
    },
    // 质押
    async handleStake () {
      if (this.currentSelect) {
        if ((this.lastTime + (+this.interval)) > ((Date.parse(new Date()) / 1000))) {
          return this.$toast(this.singleStake.stakeNotice)
        }
      }
      if (this.mebAmount <= 0) {
        return
      }
      this.btnLoading = true
      if (this.mebAmount > this.mebBalance) {
        this.btnLoading = false
        return this.$toast(this.singleStake.lack)
      }
      const resp = await singleLock.handleDeposit(this.currentSelect, this.mebAmount)
      if (resp.success) {
        this.$toast('success')
        this.$parent.init()
        this.closePop()
      } else {
        this.$toast(resp.message)
      }
      this.btnLoading = false
    },
    // 获取totalAllocPoint
    async getTotalAllocPoint () {
      const resp = await singleLock.totalAllocPoint()
      // console.log(resp)
      if (resp.success) {
        this.totalAllocPoint = resp.result
        this.getAwardPerSecond()
      }
    },
    // 获取meb每秒奖励
    async getAwardPerSecond () {
      const resp = await singleLock.awardPerSecond()
      // console.log(resp)
      if (resp.success) {
        this.awardPerSecond = resp.result / 1e18
        this.getPoolBalance()
      }
    },
    // 获取池子余额
    async getPoolBalance () {
      const resp = await this.$web3.tokenBalance(this.tokenMeb.address, config.singleStakeAddress)
      if (resp.success) {
        this.poolBalance = this.$gbUtils.formatThreeBalance(resp.result) || 0
        this.getApy(+this.poolList[0].allocPoint)
        this.xMebRate = this.poolList[0].xMebExchangeRate / 1e18
      }
    },
    // 计算质押apy
    getApy (allocPoint) {
      const perDay = (allocPoint / this.totalAllocPoint) * (+this.awardPerSecond) * 86400
      this.apy = this.$gbUtils.formatThreeBalance(((perDay / (+this.poolBalance)) * 365) * 100) + '%'
      this.weight = (allocPoint / this.totalAllocPoint).toFixed(2)
    },
    getUnit (value) {
      if (+value >= 86400) {
        return this.singleStake.day
      } else if (+value >= 3600) {
        return this.singleStake.h
      } else if (+value >= 60) {
        return this.singleStake.min
      } else {
        return this.singleStake.current
      }
    },
    async getUserDepositInterval () {
      const resp = await singleLock.userDepositInterval()
      this.interval = resp.result
    }
  },
  watch: {
    mebAmount (val) {
      if (val) {
        this.xMebAmount = this.$gbUtils.formatThreeBalance((val * this.xMebRate))
        // console.log(+val)
        // console.log(+this.mebAllowance)
        // console.log(+val > +this.mebAllowance)
        if (+val > +this.mebAllowance) {
          this.authMEB = false
        } else {
          this.authMEB = true
        }
      } else {
        this.xMebAmount = 0
      }
    },
    myAcount () {
      this.init()
    }
  },
  mounted () {
    this.init()
  }
}
</script>
<style lang="scss" scoped>
.lp-wrap {
  padding: 20px;
  box-sizing: border-box;
  .title-wrap {
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: 20px;
    padding-top: 10px;
    .title {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      font-weight: bold;
      color: #2a2b33;
    }
    .close-icon {
      cursor: pointer;
      width: 20px;
      height: 20px;
      background-color: #66eab9 !important;
      border-radius: 10px;
      text-align: center;
      line-height: 10px;
      color: #fff;
      font-size: 12px;
      font-weight: bold;
    }
  }
  .select-stake {
    .title {
      padding-top: 20px;
      font-size: 12px;
      font-weight: bold;
    }
    .active-item {
      color: #fff !important;
      background-color: #a85fff !important;
    }
    .select-group {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-bottom: 30px;

      .select-item {
        font-size: 15px;
        box-sizing: border-box;
        margin-right: 10px;
        margin-top: 10px;
        border-radius: 10px;
        background: #f0f0f0;
        text-align: center;
        line-height: 45px;
        color: #16172a;
        font-weight: bold;
        height: 45px;
        width: 30%;
      }
    }
    .key-value {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      align-items: center;
      margin-top: 10px;
    }
    .value {
      font-weight: bold;
    }
  }
  .lp-box {
    margin-top: 20px;
    .lp-balance {
      font-size: 12px;
      font-weight: bold;
      color: #16172a;
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      div:last-child {
        font-weight: normal;
      }
    }
    .input-box {
      border-radius: 15px;
      display: flex;
      align-items: center;
      padding: 0 10px 0 20px;
      box-sizing: border-box;
      justify-content: space-between;
      background: #f0f0f0;
      input {
        background: #f0f0f0;
        border: none;
      }
      .max-btn {
        cursor: pointer;
        width: 66px;
        height: 30px;
        background: #66eab9;
        opacity: 1;
        border-radius: 10px;
        font-size: 12px;
        line-height: 30px;
        text-align: center;
        font-weight: bold;
      }
    }
    .meb-amount {
      padding-top: 20px;
      color: #66eab9;
      font-size: 12px;
      font-weight: bold;
    }
    .meb-balance {
      padding-top: 10px;
      font-size: 12px;
    }
  }
  .lp-btn-groups {
    text-align: center;
    margin-top: 20px;
    bottom: 20px;
    left: 50%;
    width: 100%;
    .approve-btn {
      width: 100%;
      height: 50px;
      line-height: 50px;
      border-radius: 10px;
      color: #000 !important;
      font-weight: bold;
      margin: 0 auto;
      cursor: pointer;
    }
  }
}
</style>

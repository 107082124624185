<template>
  <Loading v-if="allLoding"></Loading>
  <div class="wrapper" v-else>
    <TabList />
    <div class="staking-wrapper">
      <div class="stake">
        <div class="title-wrap">{{ singleStake.stake }}</div>
        <div class="container">
          <!-- meb收入 -->
          <div class="profit-box">
            <div class="text">
              <div class="title">{{ singleStake.income }}</div>
              <div class="meb-amount">
                {{ $gbUtils.formatSixBalance(totalProfitM) }}
              </div>
              <div class="usdt">
                ~ {{ $gbUtils.formatThreeBalance(totalProfitM * mebPrice) }} USD
              </div>
            </div>
            <div
              class="get-button"
              ref="harvestBatchButton"
              @click="handleHarvestBatch"
            >
              <span v-if="!harvestAllLoading">{{ singleStake.extract }}</span>
              <van-loading v-else color="#fff" size="18" />
            </div>
          </div>
          <!--质押-->
          <div class="stake-box">
            <div class="text">
              <div class="title">{{ singleStake.mebTotalStake }}</div>
              <div class="amount">
                <div class="meb-amount">{{ totalStakeAmount }}</div>
                <div class="usdt">
                  ~
                  {{
                    $gbUtils.formatThreeBalance(
                      this.totalStakeAmount * this.mebPrice
                    )
                  }}
                  USD
                </div>
              </div>
            </div>
            <VanButton disabled class="stake-button"  @click="handleStakeShow">
              {{ singleStake.stake }}
            </VanButton>
          </div>
          <!-- x-meb余额 -->
          <div class="stake-box">
            <div class="text">
              <div class="title">xMeb {{ staking.balance }}</div>
              <div class="meb-amount">{{ totalXMeb }}</div>
            </div>
            <div class="get-button" @click="handleAddXMeb">
              {{ singleStake.add }}
            </div>
          </div>
        </div>
      </div>
      <div class="lock">
        <div class="title-wrap">{{ singleStake.currentLock }}</div>
        <div class="container">
          <div class="meb-box">
            <!-- 活期锁仓 -->
            <div class="box-item">
              <div class="title">{{ singleStake.currentMeb }}</div>
              <div class="meb-amount">
                {{
                  $gbUtils.formatThreeBalance(currentPoolInfo.amount / 1e18) ||
                  0
                }}
              </div>
            </div>
            <!-- 定期锁仓 -->
            <div class="box-item">
              <div class="title">{{ singleStake.extractMeb }}</div>
              <div class="meb-amount">
                {{
                  $gbUtils.formatThreeBalance(currentPoolInfo.amount / 1e18) ||
                  0
                }}
              </div>
            </div>
            <div
              class="get-button"
              @click="handleHarvestCurrent"
              ref="harvestCurrentButton"
            >
              <span v-if="!harvestLoading">{{ singleStake.extract }}</span>
              <van-loading v-else color="#fff" size="18" />
            </div>
          </div>
        </div>
        <div class="title-wrap die-title">{{ singleStake.regularLock }}</div>
        <div class="container">
          <RegularList
            v-for="(item, index) in stakePoolList"
            :key="item.lockSecond"
            :pid="index + 1"
            :title="item.stakeTime + getUnit(item.lockSecond)"
            :lockSecond="item.lockSecond"
            :dataSource="stakePoolData[index]"
            :poolInfo="stakePoolList[index]"
            :punishRate="punishRate"
          ></RegularList>
        </div>
      </div>
    </div>
    <RedeemPop v-if="redeemShow" @close="handleRedeemShow"></RedeemPop>
    <StakePop
      v-if="stakeShow"
      @close="handleStakeShow"
      :poolList="poolList"
      :lastTime="lastTime"
    ></StakePop>
  </div>
</template>

<script>
import config from '@/config/app.config'
import pancakeObj from '@/utils/pancakeRouter'
import { mapState } from 'vuex'
import RegularList from './components/RegularList.vue'
import TabList from '../components/TabList.vue'
import RedeemPop from './components/RedeemPop.vue'
import StakePop from './components/StakePop.vue'
import singleLock from '@/utils/singleStake'
import Loading from '../../../components/common/Loading.vue'
const contractsInfo = config.contractsInfo
export default {
  name: 'SingleStaking',
  data () {
    return {
      allLoding: true, // 全局Loding
      harvestLoading: false,
      harvestAllLoading: false,
      // tokenMeb: contractsInfo.tokenGEPContract, // meb
      tokenxMeb: contractsInfo.tokenXMEBContract, // xMeb
      tokenMeb: contractsInfo.tokenMMebContract, // mMeb
      redeemShow: false,
      stakeShow: false,
      poolList: [], // 池子列表
      stakePoolList: [], // 锁仓池子列表
      // totalProfitM: 0, // 总收益MEB
      mebPrice: 0, // MEB价格
      totalStakeAmount: 0, // 总质押MEB
      stakePoolData: [], // 我的锁仓池子数据
      avaliableMeb: 0, // 可提取的MEB
      currentPoolInfo: {}, // 我的活期池子数据
      totalAllocPoint: 0,
      punishRate: 0, // 惩罚比例
      pendingProfit: 0, // 活期收入
      deposeProfit: 0, // 定期收入
      lastTime: 0, // 最新质押时间
      totalXMeb: 0
    }
  },
  methods: {
    init () {
      this.avaliableMeb = 0
      this.pendingProfit = 0
      this.deposeProfit = 0
      this.poolList = []
      this.stakePoolList = []
      this.stakePoolData = []
      // this.totalProfitM = 0
      this.totalStakeAmount = 0
      this.getTotalAllocPoint()
      this.getMebPrice()
      this.getProfit()
      this.getUserCurrentInfos()
      // this.poolInfos()
      this.getXMebBalance()
      this.getAllPool()
    },
    // 获取池子列表
    async getAllPool () {
      const resp = await singleLock.allPool()
      if (!resp.success) return
      if (resp.success) {
        resp.result.forEach((item, index) => {
          if (index !== 0) {
            this.getPoolData(index)
            this.stakePoolList.push({
              accAwardPerShare: item.accAwardPerShare,
              allocPoint: item.allocPoint,
              lastRewardTimestamp: item.lastRewardTimestamp,
              lockSecond: item.lockSecond,
              totalAmount: item.lockSecond,
              xMebExchangeRate: item.xMebExchangeRate,
              stakeTime: this.getTime(+item.lockSecond)
            })
          }
          this.poolList.push({
            accAwardPerShare: item.accAwardPerShare,
            allocPoint: item.allocPoint,
            lastRewardTimestamp: item.lastRewardTimestamp,
            lockSecond: item.lockSecond,
            totalAmount: item.lockSecond,
            xMebExchangeRate: item.xMebExchangeRate,
            stakeTime: this.getTime(+item.lockSecond)
          })
        })

        this.allLoding = false
      }
    },
    // 获取锁仓池数据
    async getPoolData (pid) {
      const resp = await singleLock.userDepositPoolInfos(this.myAcount, pid)
      if (resp.success) {
        this.stakePoolData[pid - 1] = resp.result
        this.handleDepositPending(resp.result, pid)
        if (resp.result.length > 0) {
          resp.result.forEach(item => {
            if (item.status) {
              this.totalStakeAmount += (item.amount / 1e18)
              if (this.lastTime < item.lockTimestampUtil - this.poolList[pid].lockSecond) {
                this.lastTime = item.lockTimestampUtil - this.poolList[pid].lockSecond
              }
            }
            //   const nowTime = Date.parse(new Date()) / 1000
            //   const lpSupply = this.poolList[pid].totalAmount
            //   if (nowTime > this.poolList[pid].lastRewardTimestamp) {
            //     console.log('lockTimestampUtil', item.lockTimestampUtil)
            //     const timeSeconds = nowTime > item.lockTimestampUtil ? item.lockTimestampUtil - this.poolList[pid].lastRewardTimestamp : nowTime - this.poolList[pid].lastRewardTimestamp
            //     console.log('timeSeconds', timeSeconds)
            //     const reward = timeSeconds * this.poolList[pid].accAwardPerShare * (this.poolList[pid].allocPoint / this.totalAllocPoint)
            //     console.log('reward', reward)
            //     const accAwardPerShare = (this.poolList[pid].accAwardPerShare / 1e18) + (reward / 1e18) / lpSupply
            //     console.log('accAwardPerShare', accAwardPerShare)
            //     const finalProfit = item.amount * (accAwardPerShare) - item.rewardDebt
            //     console.log('finalProfit', finalProfit / 1e18)
            //   }
          })
        }
      }
    },
    // 质押是否显示
    handleStakeShow () {
      this.stakeShow = !this.stakeShow
    },
    // 获取活期收益
    async getProfit () {
      const resp1 = await singleLock.availableWithdrawBalance(this.myAcount)
      if (resp1.success) {
        this.avaliableMeb = resp1.result / 1e18
      }
      const resp2 = await singleLock.pendingMeb(this.myAcount, 0)
      if (resp2.success) {
        this.pendingProfit = resp2.result
      }
    },
    // 获取meb的单价
    async getMebPrice () {
      const resp = await pancakeObj.getAmountsIn(1)
      if (!resp.success) return
      this.mebPrice = resp.result
    },
    // 获取余额
    async getXMebBalance () {
      const mebResp = await this.$web3.balanceOf(this.tokenxMeb)
      if (mebResp.success) {
        this.totalXMeb = this.$gbUtils.formatThreeBalance(mebResp.result) || 0
      }
    },
    // 获取池子信息
    // async poolInfos () {
    //   const resp = await singleLock.poolInfos(0)
    //   console.log('poolInfos', resp)
    // },
    // 提取所有收益
    async handleHarvestBatch () {
      if (this.totalProfitM === 0) return
      this.harvestAllLoading = true
      this.$refs.harvestBatchButton.style.pointerEvents = 'none'
      const resp = await singleLock.handleHarvestBatch()
      if (resp.success) {
        this.$toast('success')
        this.init()
      }
      this.$refs.harvestBatchButton.style.pointerEvents = 'auto'
      this.harvestAllLoading = false
    },
    async handleHarvestCurrent () {
      if (this.currentPoolInfo.amount <= 0) return
      this.$refs.harvestCurrentButton.style.pointerEvents = 'none'
      this.harvestLoading = true
      const resp = await singleLock.withdraw(0, this.currentPoolInfo.amount, 0, false)
      if (resp.success) {
        this.$toast('success')
        this.init()
      }
      this.$refs.harvestCurrentButton.style.pointerEvents = 'auto'
      this.harvestLoading = false
    },
    // 获取用户活期账户
    async getUserCurrentInfos () {
      const resp = await singleLock.userCurrentInfos(this.myAcount)
      if (resp.success) {
        this.currentPoolInfo = resp.result
        this.totalStakeAmount += this.$gbUtils.formatThreeBalance(resp.result.amount / 1e18)
      }
    },
    // 获取totalAllocPoint
    async getTotalAllocPoint () {
      const resp = await singleLock.totalAllocPoint()
      // console.log(resp)
      if (resp.success) {
        this.totalAllocPoint = resp.result
      }
    },
    // 计算质押的pending
    handleDepositPending (item, pid) {
      item.forEach(async (item, index) => {
        const resp = await singleLock.pendingDeposit(this.myAcount, pid, index)
        if (resp.success) {
          this.deposeProfit += (resp.result / 1e18)
        }
      })
    },
    // 获取惩罚系数
    async getPunishMulti () {
      const resp = await singleLock.punishMulti()
      if (resp.success) {
        this.punishRate = resp.result / 1e18
      }
    },
    // 添加XMB
    async handleAddXMeb () {
      const resp = await this.$web3.addToken(this.tokenxMeb.address, 'xMeb')
      console.log(resp)
    },
    // 计算分钟,小时,天
    getTime (value) {
      if (+value >= 86400) {
        return (+value / 86400).toFixed(0)
      } else if (+value >= 3600) {
        return (+value / 3600).toFixed(0)
      } else if (+value >= 60) {
        return (+value / 60).toFixed(0)
      } else if (+value === 0) {
        return this.singleStake.current
      }
    },
    // 获取天小时单位
    getUnit (value) {
      if (+value >= 86400) {
        return this.singleStake.day
      } else if (+value >= 3600) {
        return this.singleStake.h
      } else if (+value >= 60) {
        return this.singleStake.min
      } else {
        return this.singleStake.current
      }
    }
  },
  computed: {
    ...mapState(['myAcount', 'mbLang']),
    singleStake () {
      return this.$t('staking.singleStake')
    },
    staking () {
      return this.$t('staking')
    },
    totalProfitM () {
      // console.log(this.avaliableMeb, 'this.1')
      // console.log(this.pendingProfit, 'this.pendingProfit')
      // console.log(this.deposeProfit, 'deposeProfit')
      const total = parseFloat(this.avaliableMeb) + parseFloat(this.pendingProfit) + parseFloat(this.deposeProfit)
      return total
    }
  },
  mounted () {
    this.init()
    this.getPunishMulti()
  },
  watch: {
    lastTime (val) {
      this.lastTime = val
    },
    myAcount () {
      this.init()
    }
  },
  components: { RegularList, TabList, RedeemPop, StakePop, Loading }
}
</script>

<style scoped lang="scss">
.staking-wrapper {
  padding: 15px;
  padding-bottom: 80px;
}
.stake {
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);

  .container {
    padding: 20px 30px 30px 30px;
    .title {
      color: #977ff1;
      font-size: 12px;
      font-weight: bold;
    }
    .usdt {
      color: #818086;
      font-size: 12px;
    }
    .meb-amount {
      color: #16172a;
      font-size: 20px;
      font-weight: bold;
    }
    .profit-box {
      box-sizing: border-box;
      padding: 15px;
      height: 90px;
      background-color: #fff;
      border-radius: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .text {
        height: 60px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
    .stake-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      padding: 15px;
      margin-top: 15px;
      background: #fff;
      border-radius: 15px;
      .title {
        padding-bottom: 7px;
      }
      .stake-button {
        padding: 0 20px;
        height: 30px;
        background: #a85ffc;
        color: #fff;
        font-size: 12px;
        line-height: 30px;
        font-weight: bold;
        text-align: center;
        border-radius: 10px;
      }
    }
    .xMeb-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      padding: 15px;
      border-radius: 15px;
      margin-top: 15px;
      height: 75px;
      background: #fff;
      .meb-amount {
        padding-top: 5px;
      }
    }
  }
}
.lock {
  margin-top: 15px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  .container {
    padding: 20px 30px 30px 30px;
    .meb-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      height: 75px;
      background: #fff;
      border-radius: 15px;
      padding: 15px;
      .box-item {
        .title {
          color: #818086;
          font-size: 12px;
        }
        .meb-amount {
          padding-top: 5px;
          font-size: 15px;
          color: #16172a;
          font-weight: bold;
        }
      }
    }
  }
  .die-title {
    border-radius: 0;
  }
}
.title-wrap {
  font-size: 15px;
  line-height: 50px;
  padding-left: 40px;
  border-radius: 10px 10px 0 0;
  font-weight: bold;
  height: 50px;
  background-color: #fff;
}

.get-button {
  padding: 0 20px;
  height: 30px;
  background: #66eab9;
  font-size: 12px;
  line-height: 30px;
  font-weight: bold;
  text-align: center;
  border-radius: 10px;
}
</style>

<template>
  <div>
    <div class="bottom">
      <div class="info">
        <div class="one-line">
          <div class="dataSource">
            <div class="title">{{ singleStake.lockTime }}</div>
            <div class="value">
              {{
                $moment(
                  (+dataSource.lockTimestampUtil - lockSecond) * 1000
                ).format('YYYY-MM-DD HH:mm')
              }}
            </div>
          </div>
          <div class="dataSource">
            <div class="title">{{ singleStake.unlockTime }}</div>
            <div class="value">
              {{
                $moment(+dataSource.lockTimestampUtil * 1000).format(
                  'YYYY-MM-DD HH:mm'
                )
              }}
            </div>
          </div>
        </div>
        <div class="dataSource">
          <div class="title">{{ singleStake.lockQuantity }}</div>
          <div class="value">{{ dataSource.amount / 1e18 }}</div>
        </div>
      </div>
      <div
        class="button"
        ref="button"
        v-if="
          +dataSource.lockTimestampUtil < Date.parse(new Date()) / 1000 &&
          dataSource.status
        "
        @click="handleWithdraw(pid, dataSource.amount, withdrawIndex, 'false')"
      >
        <span v-if="!withdrawLoding">{{ singleStake.extract }}</span>
        <van-loading v-else color="#fff" size="18" />
      </div>
      <div
        class="button button-unlock"
        ref="button"
        v-else-if="
          +dataSource.lockTimestampUtil > Date.parse(new Date()) / 1000 &&
          dataSource.status &&
          !authXmeb
        "
        @click="approveMEBContract"
      >
        <span v-if="!authMEBLoading">{{ singleStake.supplyX }}</span>
        <van-loading v-else color="#fff" size="18" />
      </div>
      <div
        class="button button-unlock"
        ref="button"
        v-else-if="
          +dataSource.lockTimestampUtil > Date.parse(new Date()) / 1000 &&
          dataSource.status &&
          authXmeb
        "
        @click="handleUnlock(dataSource.amount, withdrawIndex)"
      >
        <span v-if="!withdrawLoding">{{ singleStake.unlock }}</span>
        <van-loading v-else color="#fff" size="18" />
      </div>
      <div class="notice" v-else>{{ singleStake.extracted }}</div>
    </div>
  </div>
</template>

<script>
import config from '@/config/app.config'
import singleLock from '@/utils/singleStake'
const contractsInfo = config.contractsInfo
export default {
  props: ['dataSource', 'lockSecond', 'withdrawIndex', 'pid', 'poolInfo', 'punishRate'],
  data () {
    return {
      tokenXMeb: contractsInfo.tokenXMEBContract,
      withdrawLoding: false,
      authXmeb: false,
      xmebAllowance: 0,
      authMEBLoading: false
    }
  },
  computed: {
    singleStake () {
      return this.$t('staking.singleStake')
    }
  },
  methods: {
    // 获取xmeb授权额度
    async getAllowance () {
      const resp = await singleLock.allowance(this.tokenXMeb)
      if (resp.success) {
        this.xmebAllowance = resp.result
        this.authXmeb = this.xmebAllowance > 0
      }
    },
    // 授权XMEB
    async approveMEBContract () {
      this.authMEBLoading = true
      this.$refs.button.style.pointerEvents = 'none'
      const MEBObj = {
        abi: this.tokenXMeb.abi,
        address: this.tokenXMeb.address
      }
      const resp = await singleLock.approveContract(MEBObj, 100000000000000000) // 授权MEB
      // if (this.$refs.authMEB) { this.$refs.button.style.pointerEvents = 'auto' }
      this.authMEBLoading = false
      this.$refs.button.style.pointerEvents = 'auto'
      if (!resp.success) { return this.$toast(resp.message.message) }
      // console.log('签名回调', resp)
      this.authXmeb = true
      this.getAllowance()
    },

    handleUnlock (amount, index) {
      console.log('index', index)
      this.withdrawLoding = true
      this.$refs.button.style.pointerEvents = 'none'
      const punishNum = this.$gbUtils.formatThreeBalance(amount / 1e18 * (this.poolInfo.xMebExchangeRate / 1e18) * this.punishRate)
      this.$dialog.confirm({
        title: this.singleStake.unlockNotice,
        message: `${this.singleStake.unlockDes} ${punishNum}xMEB`,
        confirmButtonColor: '#a85ffc',
        confirmButtonText: this.singleStake.confirm,
        cancelButtonText: this.singleStake.cancle
      }).then(() => {
        this.handleWithdraw(this.pid, amount, index, true)
      }).catch(() => {
        this.withdrawLoding = false
        this.$refs.button.style.pointerEvents = 'auto'
      })
    },
    async handleWithdraw (pid, amount, index, force) {
      this.withdrawLoding = true
      this.$refs.button.style.pointerEvents = 'none'
      const resp = await singleLock.withdraw(pid, amount, index, force)
      if (resp.success) {
        this.$parent.$parent.init()
      }
      this.$refs.button.style.pointerEvents = 'auto'
      this.withdrawLoding = false
    }
  },
  mounted () {
    this.getAllowance()
  }
}
</script>

<style lang="scss" scoped>
.bottom {
  box-sizing: border-box;
  width: 100%;
  padding: 5px 10px 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  border-bottom: 1px solid #e3e3e3;
  .info {
    width: 60%;
    .one-line {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .dataSource {
      .title {
        font-weight: bold;
      }
      .value {
        color: #818086;
      }
      margin-top: 10px;
    }
  }
  .button {
    padding: 0 15px;
    height: 30px;
    background: #66eab9;
    text-align: center;
    line-height: 30px;
    font-weight: bold;
    border-radius: 10px;
  }
  .notice {
    padding: 0 20px;
    text-align: center;
    color: #818086;
    line-height: 30px;
    height: 30px;
  }
  .button-unlock {
    background: #a85ffc;
    color: #fff;
  }
}
.bottom:last-child {
  border: none;
}
.none-data {
  font-size: 12px;
  color: #818086;
  text-align: center;
}
</style>

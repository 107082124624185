import Web3 from 'web3'
import store from '@/store'
import config from '@/config/app.config.js' // 引入配置文件
import singleStakeAbi from '@/static/abi/singleStake.json' // 单币质押合约ABI

let web3
try {
  web3 = new Web3(window.ethereum)
  // web3 = new Web3(new Web3.providers.HttpProvider('https://data-seed-prebsc-1-s1.binance.org:8545'))
} catch {
  web3 = new Web3(
    new Web3.providers.HttpProvider('https://bsc-dataseed1.ninicoin.io')
  )
}
const singleStakeAddress = config.singleStakeAddress
const currentAccount = store.state.myAcount // 当前钱包账户
const contractStake = new web3.eth.Contract(singleStakeAbi, singleStakeAddress)

// 统一错误返回
const failResult = (message) => ({ success: false, message })
// 统一成功返回
const successResult = (result) => ({ success: true, result })

/**
 * allPool 池子列表
 * @return
 */
const allPool = async () => {
  try {
    const resp = await contractStake.methods
      .allPool()
      .call({ from: currentAccount })
    return successResult(resp)
  } catch (error) {
    return failResult(error)
  }
}

/**
 * pendingMeb 获取收益数据
 * @pid 池子id
 * @address 地址
 * @return 收益数据
 */
const pendingMeb = async (address, pid) => {
  try {
    const resp = await contractStake.methods
      .pending(address, pid)
      .call({ from: currentAccount })
    // console.log(resp)
    const mebValue = web3.utils.fromWei(resp, 'ether')
    return successResult(mebValue)
  } catch (error) {
    return failResult(error)
  }
}

/**
 *   allowance, 授权dex使用币的额度
 * @tokenObj 合约币种对象
 * @return 额度
 */
const allowance = async (tokenObj) => {
  const accounts = await web3.eth.getAccounts()
  const myContract = await new web3.eth.Contract(tokenObj.abi, tokenObj.address)
  try {
    var resp = await myContract.methods
      .allowance(accounts[0], singleStakeAddress)
      .call({
        from: accounts[0]
      })
    resp = web3.utils.fromWei(resp, 'ether')
    return successResult(resp)
  } catch (error) {
    console.log(error)
    return failResult(error)
  }
}

/**
 * approveContract 授权dex使用币给pancake
 * @tokenObj dex币的合约对象
 * @param inputValue 输入的数量
 */

const approveContract = async (tokenObj, inputValue) => {
  const accounts = await web3.eth.getAccounts()
  const stringValue = web3.utils.toWei(inputValue.toString(), 'ether')
  const myContract = await new web3.eth.Contract(tokenObj.abi, tokenObj.address)
  try {
    const resp = await myContract.methods
      .approve(singleStakeAddress, stringValue)
      .send({
        from: accounts[0]
      })
    return successResult(resp)
  } catch (error) {
    console.log(error)
    return failResult(error)
  }
}

/**
 * poolInfos 池子信息
 * @pid 池子id
 * @return 池子信息
 */
const poolInfos = async (pid) => {
  try {
    const resp = await contractStake.methods
      .poolInfos(pid)
      .call({ from: currentAccount })
    return successResult(resp)
  } catch (error) {
    return failResult(error)
  }
}

/**
 * punishMulti 惩罚比例
 * @return 池子信息
 */
const punishMulti = async () => {
  try {
    const resp = await contractStake.methods
      .punishMulti()
      .call({ from: currentAccount })
    return successResult(resp)
  } catch (error) {
    return failResult(error)
  }
}

/**
 * poolInfos 池子信息
 * @pid 池子id
 * @amount 质押量
 * @return
 */
const handleDeposit = async (pid, amount) => {
  const accounts = await web3.eth.getAccounts()
  pid = pid.toString()
  amount = web3.utils.toWei(amount.toString(), 'ether')
  try {
    const resp = await contractStake.methods
      .deposit(pid, amount)
      .send({ from: accounts[0], gasLimit: 300000 })
    return successResult(resp)
  } catch (error) {
    return failResult(error)
  }
}

/**
 * havest 提现池子
 * @pid 池子ID
 * @return
 */
const handleHarvest = async (pid) => {
  const accounts = await web3.eth.getAccounts()
  try {
    const resp = await contractStake.methods
      .harvest(pid)
      .send({ from: accounts[0] })
    // console.log(resp)
    return successResult(resp)
  } catch (error) {
    return failResult(error)
  }
}

/**
 * havestBatch 提现所有
 * @return
 */
const handleHarvestBatch = async () => {
  const accounts = await web3.eth.getAccounts()

  try {
    const resp = await contractStake.methods
      .harvestBatch()
      .send({ from: accounts[0] })
    // console.log(resp)
    return successResult(resp)
  } catch (error) {
    return failResult(error)
  }
}

/**
 * withdraw
 * @return
 */
const withdraw = async (pid, amount, index, force) => {
  const accounts = await web3.eth.getAccounts()
  try {
    if (force === 'false') {
      force = false
    }
    console.log('force', force)
    const resp = await contractStake.methods
      .withdraw(pid, amount, index, force)
      .send({ from: accounts[0] })
    // console.log(resp)
    return successResult(resp)
  } catch (error) {
    return failResult(error)
  }
}

/**
 * 获取 AllocPoint
 * @return
 */
const allocPoint = async () => {
  try {
    const resp = await contractStake.methods
      .allocPoint()
      .call({ from: currentAccount })
    // console.log(resp)
    return successResult(resp)
  } catch (error) {
    return failResult(error)
  }
}

/**
 * 获取 totalAllocPoint
 * @return
 */
const totalAllocPoint = async () => {
  try {
    const resp = await contractStake.methods
      .totalAllocPoint()
      .call({ from: currentAccount })
    // console.log(resp)
    return successResult(resp)
  } catch (error) {
    return failResult(error)
  }
}

/**
 * 获取 totalAllocPoint
 * @return
 */
const awardPerSecond = async () => {
  try {
    const resp = await contractStake.methods
      .awardPerSecond()
      .call({ from: currentAccount })
    // console.log(resp)
    return successResult(resp)
  } catch (error) {
    return failResult(error)
  }
}

/**
 * 获取 用户质押信息
 * @address 用户地址
 * @pid 池子id
 * @index 用户地址
 * @return
 */
const userDepositInfos = async (address, pid, index) => {
  try {
    const resp = await contractStake.methods
      .userDepositInfos(address, pid, index)
      .call({ from: currentAccount })
    // console.log(resp)
    return successResult(resp)
  } catch (error) {
    return failResult(error)
  }
}

/**
 * 获取 用户质押池子信息
 * @address 用户地址
 * @pid 池子id
 * @return
 */
const userDepositPoolInfos = async (address, pid) => {
  try {
    const resp = await contractStake.methods
      .userDepositPoolInfos(address, pid)
      .call({ from: currentAccount })
    return successResult(resp)
  } catch (error) {
    return failResult(error)
  }
}

/**
 * 获取 可提取余额
 * @address 用户地址
 * @pid 池子id
 * @return
 */
const availableWithdrawBalance = async (address) => {
  try {
    const resp = await contractStake.methods
      .availableWithdrawBalance(address)
      .call({ from: currentAccount })
    // console.log('resp', resp)
    return successResult(resp)
  } catch (error) {
    return failResult(error)
  }
}

/**
 * 获取定期可提现
 * @address 用户地址
 * @pid 池子id
 * @return
 */
const pendingDeposit = async (address, pid, index) => {
  try {
    const resp = await contractStake.methods
      .pendingDeposit(address, pid, index)
      .call({ from: currentAccount })
    return successResult(resp)
  } catch (error) {
    return failResult(error)
  }
}
/**
 * 获取用户活期数据
 * @address 用户地址
 * @return
 */
const userCurrentInfos = async (address) => {
  try {
    const resp = await contractStake.methods
      .userCurrentInfos(address)
      .call({ from: currentAccount })
    return successResult(resp)
  } catch (error) {
    return failResult(error)
  }
}

const userDepositInterval = async () => {
  try {
    const resp = await contractStake.methods
      .userDepositInterval()
      .call({ from: currentAccount })
    return successResult(resp)
  } catch (error) {
    return failResult(error)
  }
}

export default {
  userDepositInterval,
  punishMulti,
  withdraw,
  userCurrentInfos,
  allPool,
  pendingDeposit,
  availableWithdrawBalance,
  userDepositPoolInfos,
  userDepositInfos,
  pendingMeb,
  allocPoint,
  totalAllocPoint,
  poolInfos,
  allowance,
  approveContract,
  handleDeposit,
  handleHarvestBatch,
  handleHarvest,
  awardPerSecond
}
